var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"affected_pk","loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"item.affected_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datatables_formatTimestamp(item.affected_at))+" ")]}},{key:"item.affected_old_values",fn:function(ref){
    var item = ref.item;
return [_c('table',{staticClass:"inner-table"},[_c('tbody',_vm._l((item.oldValuesObj),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0)])]}},{key:"item.affected_new_values",fn:function(ref){
    var item = ref.item;
return [_c('table',{staticClass:"inner-table"},[_c('tbody',_vm._l((item.newValuesObj),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(value))])])}),0)])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }